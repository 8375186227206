import produce from 'immer'

interface ReducerState {
  campaignDetails: { [key: string]: any },
  currentSelectedLanguage: string,
  locale: string,
  getAllGlobalTranslation: null|object
}

export const languageCodes = {
  English: 'en',
  Assamese: 'as',
  Hindi: 'hi',
  Bengali: 'bn',
  Marathi: 'mr',
  Gujarati: 'gu',
  Urdu: 'ur',
  Malayalam: 'ml',
  Kannada: 'kn',
  Kashmiri: 'ks',
  Sanskrit: 'sa',
  Sindhi: 'sd',
  Tamil: 'ta',
  Telugu: 'te',
  Punjabi: 'pa',
  'Odia/Oriya': 'or',
  Arabic: 'ar',
  Spanish: 'es',
  Portuguese: 'pt',
  German: 'de',
  French: 'fr',
  Chinese: 'zh',
  Indonesian: 'id',
  Turkish: 'tr',
}

const initialState: ReducerState = {
  campaignDetails: {},
  currentSelectedLanguage: 'English',
  locale: 'en',
  getAllGlobalTranslation: null
}

const campaignDetailsReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case 'CAMPAIGN_SELECTED': {
      draft.campaignDetails = action.payload.campaignDetails
      localStorage.setItem('campaignLogo', JSON.stringify(action.payload.campaignDetails.campaignLogo))
      break
    }
    case 'CLEAR_CAMPAIGN_DETAILS': {
      draft.campaignDetails = {}
      break
    }
    case 'CLEAR_CAMPAIGN_TYPE': {
      draft.campaignDetails = { ...draft.campaignDetails, campaignType: undefined }
      break
    }
    case 'UPDATE_LANGUAGE': {
      draft.currentSelectedLanguage = action.payload.language
      draft.locale = languageCodes[action.payload.language]
      break
    }
    case 'GET_CAMPAIGN_TRANSLATION': {
      draft.getAllGlobalTranslation = action.payload.getAllGlobalTranslation
      break
    }
    default:
  }
})

export default campaignDetailsReducer
